<template>
  <div class="home_page">
    <div class="div-frame content-scroll">
      <div class="mb30">
        <div style="display: flex">
          <div class="div-title mb12">创建成绩</div>
          <div
            class="div-createScoreNumber"
            style="border-radius: 4px"
            v-if="scoreHistory && scoreHistory.limit"
          >
            今日剩余
            <span style="color: rgb(233, 48, 45)" v-if="scoreHistory">{{ editNum }}</span>
            次
          </div>
        </div>
        <div class="div-vice-title">成绩决定推荐结果及录取概率，请认真填写</div>
      </div>
      <div style="height: 500px" class="create_box">
        <div class="mb25">
          <div class="mb5 create_title">
            <span
              v-for="(name, index) in createTitle"
              :key="index"
              :class="createActive == name.name ? 'active' : ''"
              @click="clickCreateTitle(name)"
              >{{ name.name }}</span
            >
          </div>
          <div class="create_input" v-if="createActive == '预估总分'">
            <div class="create_input_box">
              <input
                placeholder="100 ~ 750"
                class="input"
                style="padding: 0px 30px; font-family: Bebas"
                v-model="grade"
              />
              <i
                class="iconfont full-in-icon el-icon-edit"
                style="
                  position: absolute;
                  left: 10px;
                  top: 28px;
                  color: rgba(0, 0, 0, 0.3);
                  font-size: 16px;
                  font-weight: 700;
                "
              ></i>
              <div
                style="
                  position: absolute;
                  top: 25px;
                  right: 12px;
                  color: rgb(153, 153, 153);
                "
              >
                分
              </div>
            </div>
          </div>
          <div class="create_input" v-else>
            <div class="create_input_box mb20">
              <input
                placeholder="100 ~ 750"
                class="input"
                style="padding: 0px 30px; font-family: Bebas"
                v-model="real_score"
              />
              <i
                class="iconfont full-in-icon el-icon-edit"
                style="
                  position: absolute;
                  left: 10px;
                  top: 28px;
                  color: rgba(0, 0, 0, 0.3);
                  font-size: 16px;
                  font-weight: 700;
                "
              ></i>
              <div
                style="
                  position: absolute;
                  top: 25px;
                  right: 12px;
                  color: rgb(153, 153, 153);
                "
              >
                分数
              </div>
            </div>
            <div class="create_input_box">
              <input
                placeholder=""
                class="input"
                style="padding: 0px 30px; font-family: Bebas"
                v-model="real_rank"
              />
              <i
                class="iconfont full-in-icon el-icon-edit"
                style="
                  position: absolute;
                  left: 10px;
                  top: 28px;
                  color: rgba(0, 0, 0, 0.3);
                  font-size: 16px;
                  font-weight: 700;
                "
              ></i>
              <div
                style="
                  position: absolute;
                  top: 25px;
                  right: 12px;
                  color: rgb(153, 153, 153);
                "
              >
                位次
              </div>
            </div>
          </div>
        </div>
        <div class="mb25">
          <div class="mb15" v-if="scoreConfig && scoreConfig.course1">
            <div class="mb15">{{ scoreConfig.course1.name }}</div>
            <ul class="subject_box">
              <li
                class="myBtn mr14 mb10 subject-btn"
                v-for="(item, index) in scoreConfig.course1.tags"
                :key="index"
                @click="handleFrist(item, scoreConfig.course1.must)"
                :class="fristActive.indexOf(item) > -1 ? 'active' : ''"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="mb15" v-if="scoreConfig && scoreConfig.course2">
            <div class="mb15">{{ scoreConfig.course2.name }}</div>
            <ul class="subject_box">
              <li
                class="myBtn mr14 mb10 subject-btn"
                v-for="(item, index) in scoreConfig.course2.tags"
                :key="index"
                @click="handleNext(item, scoreConfig.course2.must)"
                :class="nextActive.indexOf(item) > -1 ? 'active' : ''"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="foot-box">
        <button class="createScoreBtn" @click="handleScore">确认成绩</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      subjectList1: [
        {
          id: 1,
          value: "物理",
        },
        {
          id: 2,
          value: "历史",
        },
      ],
      subjectList: [
        {
          id: 1,
          value: "化学",
        },
        {
          id: 2,
          value: "生物",
        },
        {
          id: 3,
          value: "地理",
        },
        {
          id: 4,
          value: "政治",
        },
      ],
      grade: null,
      real_score: null, //高考分数
      real_rank: null, //位次
      frist: [],
      next: [],
      fristActive: [],
      nextActive: [],
      listHistory: [],
      scoreHistory: null,
      editNum: 0,
      gradeStart: null,
      real_scoreStart: null, //高考分数
      real_rankStart: null, //位次
      scoreConfig: null,
      createTitle: [
        {
          name: "预估总分",
          type: 1,
        },
        {
          name: "高考分数",
          type: 2,
        },
      ],
      createActive: "预估总分",
      disableShow: false,
    };
  },
  computed: {
    ...mapState({
      // userInfo: (state) => state.userinfo,
      currentProvince: (state) => state.currentProvince,
      currentYear: (state) => state.currentYear,
      isNew: (state) => state.isNew,
    }),
  },
  watch: {
    userInfo: function (newVal) {
      if (this.userInfo && !this.userInfo.province) {
        // this.$router.push({ name: 'SelectCity' });
      }
      if (
        newVal &&
        !this.piciInfo &&
        !this.userInfo.is_new &&
        this.userInfo.province &&
        this.userInfo.point
      ) {
        // this.getScorePiciTuijian();
      }
      if (newVal && this.zhiyuanNum == 0) {
        this.getMyVolunteer();
      }
    },
  },
  async mounted() {
    const { path, query } = this.$router.currentRoute;
    await this.getScoreConfig();
    await this.getScoreHistory();
  },
  methods: {
    clickCreateTitle(name) {
      this.createActive = name.name;
    },
    async getScoreConfig() {
      let localProvinceInfo =
        JSON.parse(localStorage.getItem("localProvinceInfo")) || null;
      const apiUrl = "/php/score.config.get";
      const res = await this.$axios.get(apiUrl);
      console.log("getScoreConfig", res);
      if (res.data.ret === 0) {
        this.scoreConfig = res.data.data[localProvinceInfo.type];
        console.log("getScoreConfig---0", this.scoreConfig);
      }
    },
    async getScoreHistory() {
      const apiUrl = "/php/user.score.get"; //'php/score.config.get';
      this.$toast({
        type: "loading",
        message: "loading...",
        overlay: true,
        duration: 0,
      });
      const res = await this.$axios.get(apiUrl);
      if (res.data.ret === 0) {
        this.scoreHistory = res.data.data;
        this.editNum =
          Number(res.data.data.limit?.max) - Number(res.data.data.limit?.now);
        // this.real_score = res.data.data.score?.real_score; //高考分数        
        this.real_scoreStart = res.data.data.score?.real_score; //对比真是高考分数        
        this.gradeStart = res.data.data.score?.predict_score?res.data.data.score.predict_score:this.real_scoreStart;

        this.real_rankStart = res.data.data.score?.real_rank; //位次
        console.log("=====getScoreHistory", res.data.data.score);

        if (!res.data.data.score) {
          setTimeout(() => {
            this.$toast.clear();
          }, 600);
          return true;
        }
        this.handleEditData(res.data.data.score);
      }
    },
    //页面分数不限
    handleEditData(query) {
      this.grade = query.predict_score ? query.predict_score : null;
      this.real_score = query.real_score ? query.real_score : null; //高考分数
      console.log('wzr=======',this.real_score);
      this.real_rank = query.real_rank ? query.real_rank : null; //位次
      let list = [];
      if (query.course.indexOf(",") > -1) {
        list = query.course?.split(",");
      } else {
        list.push(query.course);
      }
      console.log(this.scoreConfig.course1, "===初始化页面", list);
      if (list) {
        this.disableShow = true;
        this.fristActive = [];
        this.nextActive = [];
        this.frist = [];
        this.next = [];
        if (this.scoreConfig && this.scoreConfig.course1.must) {
          this.scoreConfig.course1.tags.forEach((ele) => {
            if (list.indexOf(ele) != -1) {
              this.fristActive.push(ele);
              this.frist.push(ele);
            }
          });
        }
        if (this.scoreConfig && this.scoreConfig.course2.must) {
          this.scoreConfig.course2.tags.forEach((ele) => {
            if (list.indexOf(ele) != -1) {
              this.nextActive.push(ele);
              this.next.push(ele);
            }
          });
        }
      }
      setTimeout(() => {
        this.$toast.clear();
      }, 1000);
    },
    //首选
    handleFrist(item, len) {
      if (this.disableShow) {
        return;
      }
      if (this.fristActive.indexOf(item) > -1) {
        let num = this.fristActive.indexOf(item.id);
        let list = [];
        let list1 = [];
        this.fristActive.forEach((ele) => {
          if (ele != item) {
            list1.push(ele);
          }
        });
        this.fristActive = list1;

        this.frist.forEach((ele) => {
          if (ele != item) {
            list.push(ele);
          }
        });
        this.frist = list;
      } else {
        if (this.frist.length == len) {
          return false;
        }
        this.fristActive.push(item);
        this.frist.push(item);
      }
      console.log("====", this.fristActive);
    },
    //在选
    handleNext(item, len) {
      if (this.disableShow) {
        return;
      }
      if (this.nextActive.indexOf(item) > -1) {
        let num = this.nextActive.indexOf(item);
        let list = [];
        let list1 = [];
        this.nextActive.forEach((ele) => {
          if (ele != item) {
            list1.push(ele);
          }
        });
        this.nextActive = list1;

        this.next.forEach((ele) => {
          if (ele != item) {
            list.push(ele);
          }
        });
        this.next = list;
      } else {
        if (this.next.length == len) {
          return false;
        }
        this.nextActive.push(item);
        this.next.push(item);
      }
      console.log("====", this.nextActive);
    },
    //确认条件
    async handleScore() {
      let course1 = this.scoreConfig && this.scoreConfig.course1;
      let course2 = this.scoreConfig && this.scoreConfig.course2;
      console.log(this.real_score,'wwwwwwwwwwwwwwwwwwwwwwww')
      console.log(this.grade);
      if (this.createActive == "预估总分") {
        if ((this.grade == "" || !Number(this.grade) )&& (this.real_score == "" || !Number(this.real_score))) {
          this.$message({
              type: "warning",
              message: `请选择正确的成绩！`,
              // duration:0
            });
            
        } else {
          if (course1.must) {
            console.log(this.frist, "course1", course1.must);
            if (this.frist.length != course1.must) {
              this.$message({
                type: "warning",
                message: `请选择${course1.name}！`,
                // duration:0
              });
            }
          }
          if (course2.must) {
            console.log("course2", course2.must);
            if (this.next.length != course2.must) {
              this.$message({
                type: "warning",
                message: `请选择${course2.name}！`,
                // duration:0
              });
              return;
            }
          }
          console.log("=====sucess");
          //php/user.score.set
          const data = {
            type: 1,
            predict_score: this.grade,
            course1: this.frist,
            course2: this.next,
          };
          let score =this.grade?this.grade:this.real_score
          if (this.gradeStart != score) {
            let msg = this.disableShow
              ? `当前填写的是预估分数相关的信息，是否确认提交？`
              : "选科只能选择1次，请认真核对";
            this.$confirm(msg, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              customClass: "table-list-message-box",
            })
              .then(async () => {
                const response = await this.$axios.post("/php/user.score.set", data);
                if (response.data.ret == 0) {
                  this.$toast("设置成功");
                  setTimeout(() => {
                    this.$router.push({ path: "/" });
                  }, 2000);
                }
              })
              .catch(() => {
                // this.$message.info('已取消删除');
              });
          } else {
            setTimeout(() => {
              this.$router.push({ path: "/" });
            }, 1000);
          }
        }
      } else {
        if ((this.grade == "" || !Number(this.grade) )&&(this.real_score == "" || !Number(this.real_score))) {
          this.$message({
            type: "warning",
            message: `请选择正确的成绩！`,
            // duration:0
          });
        } 
        // else if (this.real_rankStart == "" || !Number(this.real_rank)) {
        //   this.$message({
        //     type: "warning",
        //     message: `请选择正确的位次！`,
        //     // duration:0
        //   });
        // }
         else {
          if (course1.must) {
            console.log(this.frist, "course1", course1.must);
            if (this.frist.length != course1.must) {
              this.$message({
                type: "warning",
                message: `请选择${course1.name}！`,
                // duration:0
              });
            }
          }
          if (course2.must) {
            console.log("course2", course2.must);
            if (this.next.length != course2.must) {
              this.$message({
                type: "warning",
                message: `请选择${course2.name}！`,
                // duration:0
              });
              return;
            }
          }
          console.log("=====sucess");
          //php/user.score.set
          const data = {
            type: 2,
            course1: this.frist,
            course2: this.next,
            real_score: this.real_score,
            real_rank: this.real_rank,
          };
          if (
            this.real_score !='' &&this.real_scoreStart!=this.real_score
          ) {
            let msg = this.disableShow
              ? `当前填写的是高考分数相关的信息，是否确认提交？`
              : "选科只能选择1次，请认真核对";
            this.$confirm(msg, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              customClass: "table-list-message-box",
            })
              .then(async () => {
                const response = await this.$axios.post("/php/user.score.set", data);
                if (response.data.ret == 0) {
                  this.$toast("设置成功");
                  setTimeout(() => {
                    this.$router.push({ path: "/" });
                  }, 2000);
                }
              })
              .catch(() => {
                // this.$message.info('已取消删除');
              });
          } else {
            setTimeout(() => {
              this.$router.push({ path: "/" });
            }, 1000);
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home_page {
  background: #fff;
  min-height: 100vh;
  .input {
    border: none;
    font-size: 46px;
    height: 60px;
    line-height: 60px;
    min-width: 60px;
  }

  .subject-btn {
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    color: #999;
  }

  .subject-btn-select,
  .subject-btn {
    width: 75px;
    height: 40px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
  }

  .subject-btn-select {
    border: 1px solid #09f;
    border-radius: 4px;
    color: #09f;
  }

  .div-flex {
    display: flex;
    justify-content: space-between;
  }

  .item {
    flex-grow: 1;
  }

  .div-flex input:nth-child(2) {
    margin-right: 0 !important;
  }

  .div-frame {
    padding: 26px 16px 0 16px;
  }

  .div-title {
    font-size: 20px;
    font-weight: 500;
    color: #333;
  }

  .div-vice-title {
    font-size: 14px;
    font-weight: 400;
    color: #999;
  }

  .createScoreBtn {
    height: 44px;
    width: 100%;
    font-size: 16px;
    background: #910000;
    border-color: #910000;
    border-radius: 100px;
    color: #fff;
  }

  .div-createScoreNumber {
    height: 20px;
    line-height: 20px;
    margin-left: 10px;
    padding: 2px 4px;
    font-size: 14px;
    font-weight: 400;
    color: #999;
  }

  .foot-box {
    position: fixed;
    left: 0;
    bottom: 20px;
    z-index: 200;
    padding: 7px 10px 0 10px;
    font-size: 16px;
    text-align: center;
    height: 60px;
    background: #fff;
    width: 100%;
  }
  .subject_box {
    display: flex;
    li {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    li.active {
      // background: #910000;
      border-color: #910000;
      color: #910000;
    }
  }
}
.el-message {
  width: 96% !important;
  min-width: auto;
}
.create_box {
  .create_title {
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI,
      Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
    color: #333;
    span {
      margin-right: 15px;
    }
    .active {
      font-size: 20px;
      color: #910000;
      font-weight: 800;
    }
  }
  .create_input {
    .create_input_box {
      position: relative;
      border-bottom: 1px solid rgb(220, 223, 230);
    }
  }
}
</style>
